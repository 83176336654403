import apiClient from './axios';

const EndPoints = {
	getBalances: `/admin/balances`,
	pending(filter) {
		let query = `/admin/transactions/pending`;

		if (filter?.type) {
			query += `?type[]=${filter?.type}`;
		}

		return query;
	}
};
// ?

// &type[]=withdrawal

export const getAllBalances = async () => {
	const res = await apiClient.get(EndPoints.getBalances);
	return res;
};
export const getAllPendingBalances = async (filter) => {
	const res = await apiClient.get(EndPoints.pending(filter));
	return res;
};
